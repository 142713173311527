import { EXPECTED_DB_VERSION } from "@auditcloud/shared/lib/constants";
import {
  CollectionNames,
  DocumentNames,
  FieldPartNames,
} from "@auditcloud/shared/lib/types/common";
import { stringifyExceptions } from "@auditcloud/shared/lib/utils/errors";
import {
  EMULATOR_AUTH_API_HTTP,
  EMULATOR_FUNCTIONS_API_HTTP,
} from "@auditcloud/shared/lib/constants";
import axios, { AxiosRequestConfig } from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {
  CACHE_SIZE_UNLIMITED,
  FirestoreSettings,
  initializeFirestore,
  enableMultiTabIndexedDbPersistence,
} from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import Vue from "vue";
import "./registerServiceWorker";
import { BackendModeInfo, SetupApp } from "./types/initApp";
import { backendBaseUrl, initApiBackend } from "./utils/HttpApi";
import { updatePwa } from "./utils/pwa";

type FirebaseConfig = {
  apiKey?: string;
  appId?: string;
  authDomain?: string;
  databaseURL?: string;
  measurementId?: string;
  messagingSenderId?: string;
  projectId?: string;
  storageBucket?: string;
  [x: string]: unknown;
};

const PROJECT_ID_KEY = "ac-project-id";
const PROJECT_ID_DEFAULT = "next-audit-dev";
const DEV_CONFIG_KEY = "dev-firebase-config";

export function isDevEnv() {
  return /^localhost|\d{1,3}(?:\.\d{1,3}){3}$/.test(window.location.hostname);
}

function readProjectId() {
  return window.localStorage.getItem(PROJECT_ID_KEY);
}

function storeProjectId(projectId: string) {
  window.localStorage.setItem(PROJECT_ID_KEY, projectId);
}

function readFirebaseConfig(): FirebaseConfig | null {
  return JSON.parse(window.localStorage.getItem(DEV_CONFIG_KEY) ?? "null");
}

function storeFirebaseConfig(firebaseConfig: FirebaseConfig) {
  window.localStorage.setItem(DEV_CONFIG_KEY, JSON.stringify(firebaseConfig));
}

function logLoadingInfo(message: string, status: null | string = null) {
  console.log(message, status);
  const loadingSpinner = document.getElementById("loading-spinner");
  const loadingProgress = document.getElementById("loading-progress");
  const loadingInfo = document.getElementById("loading-info");

  if (loadingSpinner && loadingProgress && loadingInfo) {
    loadingSpinner.style.stroke = "#0597a7";
    if (status !== null) {
      loadingSpinner.textContent = status;
    }
    loadingInfo.textContent = loadingInfo.textContent + message;
  }
}

export function displayLoadingError(err: unknown) {
  console.error(err);
  const loadingSpinner = document.getElementById("loading-spinner");
  const loadingProgress = document.getElementById("loading-progress");
  const loadingInfo = document.getElementById("loading-info");

  if (loadingSpinner && loadingProgress && loadingInfo) {
    loadingSpinner.style.stroke = "#a34c4c";
    loadingSpinner.textContent = "Error";
    loadingInfo.textContent = stringifyExceptions(err, isDevEnv());
  }
}

async function fetchFirebaseConfig(url: string): Promise<FirebaseConfig> {
  const resp = await fetch(url);
  if (resp.status !== 200) {
    throw new Error(`http ${resp.status} ${resp.statusText}`);
  }
  const firebaseConfig: FirebaseConfig = await resp.json();

  window.TheFirebaseConfig = firebaseConfig;
  return firebaseConfig;
}

async function promptProjectId(projectId: string | null): Promise<string> {
  logLoadingInfo("Project id is required", "Input");
  const form = document.getElementById(
    "loading-dev-config-form"
  ) as HTMLDivElement | null;
  const projectIdInput = document.getElementById(
    "loading-dev-config-input"
  ) as HTMLInputElement | null;
  const loadBtn = document.getElementById(
    "loading-dev-config-btn"
  ) as HTMLButtonElement | null;

  if (!(form && projectIdInput && loadBtn)) {
    console.error(form, projectIdInput, loadBtn);
    throw new Error("Prompt for project id failed");
  }

  const res = new Promise<string>((resolve, reject) => {
    console.log("Wait for Input ...");

    projectIdInput.value = projectId ?? PROJECT_ID_DEFAULT;
    projectIdInput.oninput = () => {
      const val = projectIdInput.value.trim();
      if (val.length > 0) {
        loadBtn.removeAttribute("disabled");
      } else {
        loadBtn.setAttribute("disabled", "disabled");
      }
    };
    projectIdInput.removeAttribute("disabled");

    loadBtn.onclick = () => {
      const val = projectIdInput.value.trim();
      if (val.length > 0) {
        loadBtn.setAttribute("disabled", "disabled");
        projectIdInput.setAttribute("disabled", "disabled");
        logLoadingInfo("Try to load config", "loading");
        resolve(val);
      }
    };
    form.style.display = "block";
  });

  return res;
}

async function tryLoadingConfig(projectId: string): Promise<FirebaseConfig> {
  let id = projectId;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      const config = await fetchFirebaseConfig(
        `https://${id}.firebaseapp.com/__/firebase/init.json`
      );
      storeProjectId(id);
      return config;
    } catch (err) {
      displayLoadingError(err);
      id = await promptProjectId(id);
    }
  }
}

async function loadFirebaseConfig(): Promise<FirebaseConfig> {
  if (isDevEnv()) {
    const firebaseConfig = readFirebaseConfig();
    if (firebaseConfig === null) {
      const projectId =
        readProjectId() ?? (await promptProjectId(PROJECT_ID_DEFAULT));
      return await tryLoadingConfig(projectId);
    } else {
      return firebaseConfig;
    }
  } else {
    return await fetchFirebaseConfig("/__/firebase/init.json");
  }
}

function enricheFirebaseConfigWithAuthDomain(config: FirebaseConfig) {
  if (/^[a-z\-0-9]+\.next-audit\.de$/.test(window.location.hostname)) {
    return {
      ...config,
      authDomain: "auth." + window.location.hostname,
    };
  } else {
    return config;
  }
}

function addCloseButtonToEmulatorWarning() {
  window.setTimeout(() => {
    console.warn("firebase-emulator-warning search ...");
    const element = document.querySelector(".firebase-emulator-warning");
    if (element) {
      const p = element.parentElement;
      p?.removeChild(element);
    } else {
      console.warn("firebase-emulator-warning not found ...");
    }
  }, 2000);
}

async function initFirestore(app: firebase.app.App, useEmulator: boolean) {
  const firestoreSettings: FirestoreSettings = {
    ignoreUndefinedProperties: true,
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    ...(useEmulator
      ? {
          host: "localhost:8081",
          ssl: false,
        }
      : {}),
  };

  const firestore = initializeFirestore(app, firestoreSettings);

  await enableMultiTabIndexedDbPersistence(firestore); // TODO: Ist das Sinnvoll? Es ist nicht möglich auf Write Promises zu warten das die Daten ggf. erst Stunden später gespeichert werden.
}

function setupEmulators(app: firebase.app.App) {
  const functions = getFunctions(app);
  connectFunctionsEmulator(
    functions,
    EMULATOR_FUNCTIONS_API_HTTP.host,
    EMULATOR_FUNCTIONS_API_HTTP.port
  );

  const auth = getAuth(app);
  connectAuthEmulator(auth, EMULATOR_AUTH_API_HTTP.url);

  addCloseButtonToEmulatorWarning();
}

async function initFirebase(config: FirebaseConfig) {
  logLoadingInfo("initializing firebase ...", "loading");

  const app = firebase.initializeApp(config); // Todo ist es sinnvoll hier einen Namen mitzugeben?
  if (process.env.VUE_APP_EMULATOR === "true") {
    setupEmulators(app);
  }

  initApiBackend(config.projectId ?? PROJECT_ID_DEFAULT);

  await initFirestore(app, process.env.VUE_APP_EMULATOR === "true");

  window.TheFirebase = app;
  window.TheProjectId = config.projectId ?? PROJECT_ID_DEFAULT;
  window.TheFirebaseConfig = config;
  storeFirebaseConfig(config);
  return app;
}

async function setupFirebase(): Promise<firebase.app.App> {
  let cfg = enricheFirebaseConfigWithAuthDomain(await loadFirebaseConfig());

  const devMode = isDevEnv();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      return await initFirebase(cfg);
    } catch (err) {
      window.localStorage.removeItem(DEV_CONFIG_KEY);
      if (devMode) {
        logLoadingInfo(
          "failed to initialize firebase try again ...",
          "loading"
        );
        cfg = enricheFirebaseConfigWithAuthDomain(await loadFirebaseConfig());
      } else {
        throw err;
      }
    }
  }
}

async function loadBackendModeInfo(
  firebaseApp: firebase.app.App
): Promise<BackendModeInfo> {
  const db = firebaseApp.firestore();
  const systemDocRef = db
    .collection(CollectionNames.CONFIGURATION)
    .doc(DocumentNames.CONFIG_SYSTEM);
  const systemDoc = await systemDocRef.get({ source: "server" });

  const backendVersion = (systemDoc.get(FieldPartNames.SYSTEM_DB_VERSION) ??
    null) as number | null;

  const invalidBackend = EXPECTED_DB_VERSION !== backendVersion;
  if (invalidBackend) {
    await updatePwa("RELOAD", "initApp:invalidBackend");
  }

  return {
    backendVersion,
    invalidBackend,
    systemDocExists: systemDoc.exists,
  };
}

function initAxios(firebaseApp: firebase.app.App) {
  axios.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const user = getAuth(firebaseApp).currentUser;
      const url = config.url;

      if (
        user &&
        url &&
        (url.startsWith(backendBaseUrl()) ||
          /[a-z0-9]+-[a-z0-9]+-(next-audit|audit-cloud)-[a-z0-9]+\.cloudfunctions\.net\//.test(
            url
          ))
      ) {
        console.log("SetToken");
        const token = await user.getIdToken();

        config.headers.Authorization = `Bearer ${token}`;
        return config;
      } else {
        console.log("External request: no token set");
        return config;
      }
    },
    (error: any) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
}

export async function initApp(): Promise<Vue | null> {
  Vue.config.productionTip = false;
  const firebaseApp = await setupFirebase();
  console.log("INIT_APP", firebaseApp, "DEV: ", isDevEnv());
  initAxios(firebaseApp);

  const maintenanceMode = await loadBackendModeInfo(firebaseApp);

  const initFunctionModul =
    maintenanceMode.invalidBackend || !maintenanceMode.systemDocExists
      ? await import(
          /* webpackChunkName: "MaintenanceApp" */ "./initMaintenanceApp"
        )
      : await import(/* webpackChunkName: "AuditCloud" */ "./initAuditCloud");
  const initFunction = initFunctionModul.default;
  const vueApp = await initFunction({
    ...maintenanceMode,
    firebaseApp,
  });

  (vueApp.$children[0] as unknown as SetupApp).setupApp({
    ...maintenanceMode,
    firebaseApp,
  });

  return vueApp;
}
