/* tslint:disable:no-console */

import { HOUR_IN_MS } from "@auditcloud/shared/lib/constants";
import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "SW: App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration) {
      console.log("SW: Service worker has been registered.");
      setInterval(() => {
        registration.update();
      }, HOUR_IN_MS); // e.g. hourly checks
    },
    cached() {
      console.log("SW: Content has been cached for offline use.");
    },
    updatefound() {
      console.log("SW: New content is downloading.");
      document.dispatchEvent(new CustomEvent("swUpdateFound"));
    },
    updated(registration) {
      console.log("SW: New content is available.", registration);
      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration })
      );
    },
    offline() {
      console.log(
        "SW: No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("SW: Error during service worker registration:", error);
    },
  });
}
