export type UpdateType = "FETCH_ONLY" | "ACTIVATE" | "RELOAD";

async function findServiceWorkerRegistration(): Promise<ServiceWorkerRegistration | null> {
  // the regular path: update the /app service worker
  const appReg = await navigator.serviceWorker.getRegistration("/app/");
  if (appReg) {
    console.log(`updatePwa: found app registration: "${appReg.scope}"`);
    return appReg;
  }

  // only relevant after reverting to an older version (worker under root):
  const rootReg = await navigator.serviceWorker.getRegistration();
  if (rootReg) {
    console.log(`updatePwa: found root registration: "${rootReg.scope}"`);
    return rootReg;
  }

  // future-compatibility: in case the path changes again:
  const fallbackRegs = await navigator.serviceWorker.getRegistrations();
  if (fallbackRegs.length > 0) {
    const [reg] = fallbackRegs;
    console.log(`updatePwa: found registration: "${reg.scope}"`);
    return reg;
  }

  console.log("updatePwa: no registration found");
  return null;
}

/**
 * Returns a (promise for) an updated service worker if one is available,
 * or `false` if no update was found.
 *
 * If the parameter `updateType` is set to `"FETCH_ONLY"`, the promise is
 * resolved as soon as the new service worker is waiting.
 *
 * If the parameter `updateType` is set to `"ACTIVATE"` (default),
 * the new service worker (if any) is asked to skip waiting for activation,
 * and the promise is resolved after the `controllerchange` event is received.
 *
 * If `immediateReload` is set to `true`, the promise returns immediately after
 * a full page reload is triggered.
 */
export async function updatePwa(
  updateType: UpdateType = "ACTIVATE",
  cause: string
): Promise<false | ServiceWorker> {
  if (!navigator.serviceWorker) {
    console.log(`updatePwa (${cause}): no service worker support. Exit`);
    return false;
  }

  const registration = await findServiceWorkerRegistration();
  if (!registration) {
    console.log(`updatePwa (${cause}): no registration found. Exit`);
    return false;
  }

  return new Promise(resolve => {
    function handleUpdate(event: Event) {
      console.log(`updatePwa (${cause}): swUpdated: activating immediately`);
      const detail = (event as CustomEvent<ServiceWorkerRegistration>).detail;
      const worker = detail.waiting;
      if (!worker) {
        console.log(`updatePwa (${cause}): swUpdated received without worker`);
        resolve(false);
        return;
      }
      if (updateType === "FETCH_ONLY") {
        resolve(worker);
        return;
      }

      navigator.serviceWorker.addEventListener(
        "controllerchange",
        () => {
          if (updateType === "ACTIVATE") {
            resolve(worker);
            return;
          }

          console.log(`updatePwa (${cause}): controllerchange: reloading app`);
          window.location.reload();
        },
        { once: true }
      );

      console.log(`updatePwa (${cause}): activating new service worker`);
      worker.postMessage("skipWaiting");
    }

    document.addEventListener("swUpdated", handleUpdate, { once: true });
    registration.update().then(() => {
      console.log(
        `updatePwa (${cause}): registration updated: `,
        `installing=${!!registration.installing}`,
        `waiting=${!!registration.waiting}`
      );
      if (!registration.installing) {
        resolve(false);
      }
    });
  });
}
