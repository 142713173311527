import {
  CLOUD_REGION,
  EMULATOR_FUNCTIONS_API_HTTP,
} from "@auditcloud/shared/lib/constants";

let PROJECT_ID = "next-audit-dev";

export function initApiBackend(projectId: string) {
  PROJECT_ID = projectId;
  window.TheProjectId = projectId;
  window.TheApiUrl = apiUrl("");
  console.log("Use ApiUrl: ", window.TheApiUrl);
}

export function backendBaseUrl() {
  if (process.env.VUE_APP_BACKEND === "live") {
    return `https://${CLOUD_REGION}-${PROJECT_ID}.cloudfunctions.net`;
  } else {
    return `${EMULATOR_FUNCTIONS_API_HTTP.url}/${PROJECT_ID}/${CLOUD_REGION}`;
  }
}

export function apiUrl(version: string = "v0") {
  const path = `/api/${version}`;
  return `${backendBaseUrl()}${path}`;
}

export function auditsEndpointUrl(...parts: string[]) {
  const path =
    `/audits` +
    (parts.length > 0 ? `/${parts.map(encodeURIComponent).join("/")}` : "");
  return `${apiUrl()}${path}`;
}

export function authEchoEndpointUrl(...parts: string[]) {
  const path =
    `/auth-echo` +
    (parts.length > 0 ? `/${parts.map(encodeURIComponent).join("/")}` : "");
  return `${apiUrl()}${path}`;
}

export function userEndpointUrl(...parts: string[]) {
  const path =
    `/user` +
    (parts.length > 0 ? `/${parts.map(encodeURIComponent).join("/")}` : "");
  return `${apiUrl()}${path}`;
}

export function endpointUrlRegisterUser() {
  const path = "/registerUser";
  return `${backendBaseUrl()}${path}`;
}

export function endpointUrlPdf() {
  const path = "/generatePdf";
  return `${backendBaseUrl()}${path}`;
}

export function measuresEndpointUrl(...parts: string[]) {
  const path =
    "/measures" +
    (parts.length > 0 ? `/${parts.map(encodeURIComponent).join("/")}` : "");
  return `${apiUrl()}${path}`;
}

export function measureEndpointUrl(measureId: string, ...parts: string[]) {
  const path =
    `/measure/${encodeURIComponent(measureId)}` +
    (parts.length > 0 ? `/${parts.map(encodeURIComponent).join("/")}` : "");
  return `${apiUrl()}${path}`;
}

export function measureStepEndpointUrl(
  measureId: string,
  measureStepId: string,
  ...parts: string[]
) {
  const path =
    `/measure/${encodeURIComponent(measureId)}/step/${encodeURIComponent(
      measureStepId
    )}` +
    (parts.length > 0 ? `/${parts.map(encodeURIComponent).join("/")}` : "");
  return `${apiUrl()}${path}`;
}

export function proxyEndpointUrl(...parts: string[]) {
  return (
    `${backendBaseUrl()}/proxy` +
    (parts.length > 0 ? `/${parts.map(encodeURIComponent).join("/")}` : "")
  );
}

export function systemEndpointUrl(...parts: string[]) {
  return (
    `${apiUrl()}/system` +
    (parts.length > 0 ? `/${parts.map(encodeURIComponent).join("/")}` : "")
  );
}

export function fileManagementEndpointUrl(...parts: string[]) {
  return (
    `${apiUrl()}/file-management` +
    (parts.length > 0 ? `/${parts.map(encodeURIComponent).join("/")}` : "")
  );
}
