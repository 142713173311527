import "babel-polyfill";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/functions";

import Vue from "vue";

import _ from "lodash";
import { displayLoadingError, initApp } from "./initApp";
import { logAppInfo } from "@auditcloud/shared/lib/apps/utils";

logAppInfo(process);

declare global {
  interface Window {
    TheFirebase?: firebase.app.App;
    TheAuditCloud?: Vue | null;
    TheFirebaseConfig?: any;
    TheProjectId?: string;
    TheApiUrl?: string;
    vuexStore?: any;
    testCall?: any;
    GTM_ID?: string;
    _: any;
  }
}

window._ = _;
initApp()
  .then(app => {
    window.TheAuditCloud = app;
  })
  .catch(err => {
    displayLoadingError(err);
  });
